import styled from "styled-components"

export const IconStack = styled.span`
  display: grid;
  svg {
    grid-area: 1 / 1;
    justify-self: center;
    align-self: center;
  }
`
