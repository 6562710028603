import { useLocation, useNavigate } from "react-router-dom"

export const useQueryStringState = (
  name: string,
  initalValue?: string
): [string, (newValue: string) => void] => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const value = query.get(name) || initalValue || ""

  const setValue = (newValue: string) => {
    query.set(name, newValue)
    navigate(`?${query.toString()}`)
  }
  return [value, setValue]
}
