import { Navigate, useLocation } from "react-router-dom"

import { useAuth } from "../hooks/use-auth"
import { getLogger } from "../utils/logging"

const logger = getLogger("Login")

export interface LocationState {
  from: {
    pathname: string
  }
}

export const Login = () => {
  const { isLoggedIn, signIn } = useAuth()
  const location = useLocation()

  if (!isLoggedIn) {
    const { from } = (location.state as LocationState) || {
      from: { pathname: "/" },
    }
    logger.info(`One day we'll deep link back to ${from.pathname}`)
    signIn()
    return null
  }

  return <Navigate to="/" />
}
