import { Card, Button, Stack } from "react-bootstrap"
import { Title } from "react-head"
import { BsPersonPlusFill } from "react-icons/bs"
import { Link } from "react-router-dom"

import { PageTitle } from "../components/Common"
import { SearchContainer } from "../containers/SearchContainer"
import { Layout } from "../layouts/Layout"

export const Search = () => (
  <Layout>
    <Title>Ancestry Project :: Search</Title>
    <Stack direction="horizontal" className="py-3 align-items-center">
      <PageTitle className="me-auto">Search</PageTitle>
      <Button as={Link as any} variant="primary" to="/person/add">
        <BsPersonPlusFill className="me-2" />
        Add Person
      </Button>
    </Stack>

    <Card className="p-4 mb-5">
      <SearchContainer />
    </Card>
  </Layout>
)
