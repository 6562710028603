import { useEffect } from "react"
import { Navigate } from "react-router-dom"

import { PersonForm } from "../../components/Person/PersonForm"
import { FormInputs } from "../../components/Person/types"
import { useAddPerson } from "../../graphql/add-person"
import { AddPersonInput } from "../../graphql/generated/graphql"
import { usePath } from "../../hooks/use-path"
import { getLogger } from "../../utils/logging"
import { notEmpty } from "../../utils/types"

const logger = getLogger("AddPersonContainer")

export const AddPersonContainer = () => {
  const { setPathState } = usePath()
  const { addPerson, addedPersonId, error, loading } = useAddPerson()

  useEffect(() => {
    setPathState({ focusPersonId: addedPersonId ?? undefined })
  }, [addedPersonId, setPathState])

  if (error) return <p>Submission error! {error.message}</p>
  if (addedPersonId) return <Navigate to="/search" />

  const handleSubmit = async ({
    birthDate,
    children,
    deathDate,
    father,
    firstName,
    gender,
    lastName,
    middleName,
    mother,
    siblings,
    spouse,
    title,
  }: FormInputs) => {
    const personInput: AddPersonInput = {
      title,
      firstName,
      middleName,
      lastName,
      gender,
      birthDate: birthDate ? birthDate : undefined,
      deathDate: deathDate ? deathDate : undefined,
      spouses: spouse ? [{ id: spouse, type: "blood" }] : [],
      siblings: siblings?.map((id: string) => ({ id, type: "blood" })) || [],
      parents: [
        mother ? { id: mother, type: "blood" } : undefined,
        father ? { id: father, type: "blood" } : undefined,
      ].filter(notEmpty),
      children: children?.map((id: string) => ({ id, type: "blood" })) || [],
    }
    try {
      await addPerson(personInput)
    } catch (e) {
      logger.error(e)
    }
  }

  return <PersonForm onSubmit={handleSubmit} loading={loading} />
}
