import { useQuery } from "@apollo/client"

import { graphql } from "./generated/gql"

export const useGetPerson = (id: string) => {
  const { error, loading, data } = useQuery(GET_PERSON, {
    variables: { personId: id },
    skip: !id,
  })

  const person = data?.person
  return { person, error, loading }
}

export const GET_PERSON = graphql(`
  query GetPerson($personId: ID!) {
    person(id: $personId) {
      ...PersonFragment
      spouses {
        id
        type
        person {
          ...PersonFragment
        }
      }
      siblings {
        id
        type
        person {
          ...PersonFragment
        }
      }
      parents {
        id
        type
        person {
          ...PersonFragment
        }
      }
      children {
        id
        type
        person {
          ...PersonFragment
        }
      }
    }
  }
`)
