import { useEffect } from "react"
import { Alert } from "react-bootstrap"
import { Navigate } from "react-router-dom"

import { Loading } from "../components/Common"
import { useAuth } from "../hooks/use-auth"
import { getLogger } from "../utils/logging"

const logger = getLogger("Callback")

export const Callback = () => {
  const { loading, isLoggedIn, error, signInCallback } = useAuth()

  useEffect(() => {
    if (!loading && !isLoggedIn && !error) {
      signInCallback()
    }
  }, [loading, isLoggedIn, error, signInCallback])

  if (!loading && isLoggedIn) {
    return <Navigate to="/" />
  }

  if (error) {
    logger.error(error)
    return <Alert variant="warning">{error}</Alert>
  }

  return <Loading className="h-100" />
}
