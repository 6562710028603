import { Navigate, Outlet, useLocation } from "react-router-dom"

import { useAuth } from "../hooks/use-auth"

export const RequireAuth = ({ children }: { children?: JSX.Element }) => {
  const { isLoggedIn } = useAuth()
  const location = useLocation()

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children ? children : <Outlet />
}
