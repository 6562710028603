import { createContext, ReactNode, useContext } from "react"

import { useGetPerson } from "../graphql/get-person"
import { useAuth } from "./use-auth"

type GetPersonResult = ReturnType<typeof useGetPerson>

const MeContext = createContext<GetPersonResult>({
  error: undefined,
  loading: false,
  person: undefined,
})
export const useMe = () => useContext(MeContext)

export const MeProvider = ({ children }: { children: ReactNode }) => {
  const value = useProvideMe()
  return <MeContext.Provider value={value}>{children}</MeContext.Provider>
}

export const useProvideMe = () => {
  const { user } = useAuth()
  return useGetPerson(user?.personId || "")
}
