import { BsPersonCircle } from "react-icons/bs"
import styled from "styled-components"

import avatar from "../../assets/images/avatar.svg"
import { Circle } from "./Circle"

interface AvatarProps {
  className?: string
  size?: string
}

export const Avatar = ({ className, size }: AvatarProps) => {
  const hasPic = false
  return hasPic ? (
    <AvatarPic
      src={avatar}
      className={[className, "rounded-circle"].join(" ")}
      alt="user"
    />
  ) : (
    <Circle className={className} size={size}>
      <BsPersonCircle />
    </Circle>
  )
}

const AvatarPic = styled.img<{ size?: string }>`
  height: ${(prop) => prop.size || "2.2em"};
  width: ${(prop) => prop.size || "2.2em"};
`
