import cx from "classnames"
import { Spinner } from "react-bootstrap"

interface LoadingProps {
  className?: string
}

export const Loading = ({ className }: LoadingProps) => (
  <div
    className={cx(
      "d-flex justify-content-center align-items-center",
      className
    )}
  >
    <Spinner animation="border" className="me-2" /> Loading...
  </div>
)
