export const Level = {
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
}

let currentLevel = Level.DEBUG

export const setLevel = (level: number) => (currentLevel = level)

export const getLogger = (name: string) => ({
  debug: (...args: any[]) => {
    if (currentLevel <= Level.DEBUG) {
      console.debug(`[${name}]`, ...args)
    }
  },
  info: (...args: any[]) => {
    if (currentLevel <= Level.INFO) {
      console.info(`[${name}]`, ...args)
    }
  },
  warn: (...args: any[]) => {
    if (currentLevel <= Level.WARN) {
      console.warn(`[${name}]`, ...args)
    }
  },
  error: (...args: any[]) => {
    if (currentLevel <= Level.ERROR) {
      console.error(`[${name}]`, ...args)
    }
  },
})
