import { HeadProvider } from "react-head"
import { IconContext } from "react-icons"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

import { RequireAuth } from "./components/RequireAuth"
import { ApolloProvider } from "./hooks/use-apollo"
import { AuthProvider } from "./hooks/use-auth"
import { MeProvider } from "./hooks/use-me"
import { PathProvider } from "./hooks/use-path"
import { Callback } from "./pages/Callback"
import { Graph } from "./pages/Graph"
import { Login } from "./pages/Login"
import { Logout } from "./pages/Logout"
import { AddPerson } from "./pages/Person/AddPerson"
import { EditPerson } from "./pages/Person/EditPerson"
import { ViewPerson } from "./pages/Person/ViewPerson"
import { Search } from "./pages/Search"
import "./styles/global.scss"

export const App = () => (
  <HeadProvider>
    <AuthProvider>
      <ApolloProvider>
        <MeProvider>
          <IconContext.Provider value={{ size: "1.25em" }}>
            <PathProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/callback" element={<Callback />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route element={<RequireAuth />}>
                    <Route
                      path="/"
                      element={<Navigate to="/graph" replace />}
                    />
                    <Route path="/graph" element={<Graph />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/person/add" element={<AddPerson />} />
                    <Route path="/person/:id/edit" element={<EditPerson />} />
                    <Route path="/person/:id" element={<ViewPerson />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </PathProvider>
          </IconContext.Provider>
        </MeProvider>
      </ApolloProvider>
    </AuthProvider>
  </HeadProvider>
)
