import { Dropdown, NavLink, Placeholder } from "react-bootstrap"
import {
  BsPerson,
  BsGear,
  BsQuestionCircle,
  BsBoxArrowRight,
} from "react-icons/bs"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { useMe } from "../../hooks/use-me"
import { getFullName } from "../../utils/person"
import { Avatar } from "../Common"

export const ProfileDropdown = () => {
  const { loading, person } = useMe()
  const fullName = person && getFullName(person)
  return (
    <Dropdown align="end" className="nav-item">
      <Dropdown.Toggle
        as={NavLink}
        className="text-dark"
        aria-label="Profile Menu"
        id="profile-menu-dropdown"
      >
        <StyledAvatar size="1.2em" className="me-2" />
        {loading ? (
          <Placeholder
            animation="glow"
            aria-hidden="true"
            data-testid="name-placeholder"
          >
            <Placeholder style={{ width: "100px" }} />
          </Placeholder>
        ) : (
          <span>{fullName || "Anonymous"}</span>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu role="section">
        <Dropdown.Item as={Link} to="/profile">
          <BsPerson className="me-2" />
          Profile
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to="/settings">
          <BsGear className="me-2" />
          Settings
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/help">
          <BsQuestionCircle className="me-2" />
          Help
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/logout">
          <BsBoxArrowRight className="me-2" />
          Sign out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const StyledAvatar = styled(Avatar)`
  margin-bottom: -15px;
  margin-top: -15px;
`
