import Alert from "react-bootstrap/Alert"
import { ErrorBoundary } from "react-error-boundary"

import { Loading } from "../components/Common"
import { Graph } from "../components/Graph/Graph"
import { Person } from "../graphql/generated/graphql"
import { useGetGraph } from "../graphql/get-graph"
import { useAuth } from "../hooks/use-auth"
import { useQueryStringState } from "../hooks/use-querystring-state"
import { getLogger } from "../utils/logging"

const logger = getLogger("GraphContainer")

const ErrorFallback = () => (
  <Alert variant="danger" className="mt-4">
    Something went wrong
  </Alert>
)

export const GraphContainer = () => {
  const [selectedRootId] = useQueryStringState("root-id")
  const { user } = useAuth()
  const rootId = selectedRootId || user?.personId || ""
  const { error, loading, graph } = useGetGraph(rootId)

  if (error) {
    logger.error(error)
    return <ErrorFallback />
  }
  if (loading) return <Loading className="h-100" />
  if (!graph) return <p>Error fetching from GraphQL</p>

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Graph people={graph as Person[]} rootId={rootId} />
    </ErrorBoundary>
  )
}
