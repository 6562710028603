import { useQuery } from "@apollo/client"

import { notEmpty } from "../utils/types"
import { graphql } from "./generated/gql"

export const useGetGraph = (rootId: string) => {
  const { error, loading, data } = useQuery(GET_GRAPH, {
    variables: { rootId: rootId },
    skip: !rootId,
  })
  const graph = data && data.graph.filter(notEmpty)
  return { graph, error, loading }
}

export const GET_GRAPH = graphql(`
  query GetGraph($rootId: ID!) {
    graph(rootId: $rootId) {
      ...PersonFragment
      spouses {
        id
        type
      }
      siblings {
        id
        type
      }
      parents {
        id
        type
      }
      children {
        id
        type
      }
    }
  }
`)
