import { Form, Row, Col } from "react-bootstrap"
import { useForm } from "react-hook-form"

import { LoadingButton } from "../Common"
import { PeopleSelect, PersonSelect } from "./PeopleSelect"
import { FormInputs } from "./types"

const Divider = () => (
  <Row>
    <Col>
      <div className="border-bottom mb-3 mt-2" />
    </Col>
  </Row>
)

export interface PersonFormProps {
  onSubmit(values: FormInputs): void
  defaultValues?: FormInputs
  loading: boolean
}

export const PersonForm = ({
  onSubmit,
  defaultValues,
  loading,
}: PersonFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues,
  })

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <Form.Group as={Col} xs="4" controlId="avatar">
          <Form.Label>Upload avatar</Form.Label>
          <Form.Control {...register("avatar")} type="file" />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} xs="4" controlId="firstName">
          <Form.Label>First name</Form.Label>
          <Form.Control
            {...register("firstName", { required: true })}
            isInvalid={!!errors.firstName}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            Please enter a first name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs="4" controlId="middleName">
          <Form.Label>Middle name</Form.Label>
          <Form.Control
            {...register("middleName")}
            isInvalid={!!errors.middleName}
            type="text"
          />
        </Form.Group>
        <Form.Group as={Col} xs="4" controlId="lastName">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            {...register("lastName")}
            isInvalid={!!errors.lastName}
            type="text"
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} xs="3" controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            {...register("title")}
            isInvalid={!!errors.title}
            type="text"
          />
        </Form.Group>
        <Form.Group as={Col} xs="3" controlId="gender">
          <Form.Label>Gender</Form.Label>
          <Form.Select
            {...register("gender")}
            isInvalid={!!errors.gender}
            aria-label="Select Gender"
          >
            <option value="">Unknown</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="non-binary">Non-binary</option>
            <option value="transgender">Transgender</option>
            <option value="intersex">Intersex</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} xs="3" controlId="birthDate">
          <Form.Label>Date of birth</Form.Label>
          <Form.Control
            {...register("birthDate")}
            isInvalid={!!errors.birthDate}
            type="date"
          />
        </Form.Group>
        <Form.Group as={Col} xs="3" controlId="deathDate">
          <Form.Label>Date of death</Form.Label>
          <Form.Control
            {...register("deathDate")}
            isInvalid={!!errors.deathDate}
            type="date"
          />
        </Form.Group>
      </Row>
      <Divider />
      <Row className="mb-4">
        <Form.Group as={Col} xs="6" controlId="mother">
          <Form.Label>Mother</Form.Label>
          <PersonSelect control={control} name="mother" />
        </Form.Group>
        <Form.Group as={Col} xs="6" controlId="father" data-testid="father">
          <Form.Label>Father</Form.Label>
          <PersonSelect control={control} name="father" />
        </Form.Group>
      </Row>
      <Divider />
      <Row className="mb-4">
        <Form.Group as={Col} xs="6" controlId="spouse">
          <Form.Label>Spouse</Form.Label>
          <PersonSelect control={control} name="spouse" />
        </Form.Group>
        <Form.Group as={Col} xs="6" controlId="siblings">
          <Form.Label>Siblings</Form.Label>
          <PeopleSelect control={control} name="siblings" />
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Form.Group as={Col} xs="6" controlId="children">
          <Form.Label>Children</Form.Label>
          <PeopleSelect control={control} name="children" />
        </Form.Group>
      </Row>
      <LoadingButton variant="primary" type="submit" loading={loading}>
        {defaultValues ? "Update" : "Add"}
      </LoadingButton>
    </Form>
  )
}
