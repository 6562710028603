import { useMutation } from "@apollo/client"

import { graphql } from "./generated/gql"
import { GET_ALL_PEOPLE } from "./get-all-people"

export const useDeletePerson = () => {
  const [mutation, { loading }] = useMutation(DELETE_PERSON, {
    update(cache, { data }) {
      if (!data) return
      const query = cache.readQuery({ query: GET_ALL_PEOPLE })
      if (!query || !query?.people) return
      cache.writeQuery({
        query: GET_ALL_PEOPLE,
        data: {
          people: query.people.filter((p) => p?.id !== data.deletePerson.id),
        },
        overwrite: true,
      })
    },
  })

  const deletePerson = async (id: string) =>
    await mutation({ variables: { id } })

  return { deletePerson, loading }
}

export const DELETE_PERSON = graphql(`
  mutation DeletePerson($id: ID!) {
    deletePerson(id: $id) {
      id
    }
  }
`)
