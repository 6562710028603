import { ReactNode } from "react"
import { Dropdown, NavLink, Row, Col } from "react-bootstrap"
import {
  BsBellSlash,
  BsClipboardCheck,
  BsInfoLg,
  BsPersonBadge,
} from "react-icons/bs"
import styled from "styled-components"

import styleVars from "../../styles/_exports.module.scss"
import { Circle } from "../Common"

interface NotificationProps {
  title?: string
  body: string
  icon?: ReactNode
}

const Notification = ({ title, body, icon }: NotificationProps) => (
  <Row>
    <Col xs={2}>{icon}</Col>
    <Col>
      <NotificationTitle>{title}</NotificationTitle>
      <p>{body}</p>
    </Col>
  </Row>
)

export const NotificationDropdown = () => (
  <Dropdown className="nav-item">
    <Dropdown.Toggle
      as={NavLink}
      className="arrow-none pe-0"
      id="notification-dropdown"
    >
      <BsBellSlash />
      <span className="visually-hidden">Notifications</span>
    </Dropdown.Toggle>

    <Dropdown.Menu
      align="end"
      variant="lg"
      className="dropdown-menu-animated"
      role="section"
    >
      <Dropdown.Header className="text-center">
        4 New Notifications
      </Dropdown.Header>
      <Dropdown.Divider />
      <Dropdown.ItemText>
        <Notification
          title="Awaiting review"
          icon={
            <Circle>
              <BsClipboardCheck />
            </Circle>
          }
          body="Abbey Heasley made changes to Cohen Heasley that's awaiting your review"
        />
      </Dropdown.ItemText>
      <Dropdown.Divider />
      <Dropdown.ItemText>
        <Notification
          title="New information"
          icon={
            <Circle>
              <BsInfoLg />
            </Circle>
          }
          body="More information was added to Cohen Heasley"
        />
      </Dropdown.ItemText>
      <Dropdown.Divider />
      <Dropdown.ItemText>
        <Notification
          title="New person added"
          icon={
            <Circle>
              <BsPersonBadge />
            </Circle>
          }
          body="Harry White was added to the family"
        />
      </Dropdown.ItemText>
    </Dropdown.Menu>
  </Dropdown>
)

const NotificationTitle = styled.h6`
  color: ${styleVars["gray-600"]};
`
