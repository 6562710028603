import { ReactNode } from "react"
import styled from "styled-components"

import styleVars from "../../styles/_exports.module.scss"

interface CircleProps {
  children: ReactNode
  className?: string
  size?: string
}

export const Circle = ({ children, className, size }: CircleProps) => (
  <StyledCircle className={[className, "rounded-circle"].join(" ")} size={size}>
    {children}
  </StyledCircle>
)

const StyledCircle = styled.div<{ size?: string }>`
  align-items: center;
  background-color: ${styleVars["gray-500"]};
  color: white;
  display: inline-flex;
  padding: 0.3em;
  font-size: ${(props) => props.size || "1em"};
  justify-content: center;
  vertical-align: middle;
`
