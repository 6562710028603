import { Button } from "react-bootstrap"
import styled from "styled-components"

export const IconButton = styled(Button).attrs({ variant: "link" })`
  padding: 0;
  border: 0;
  font-size: inherit;
  line-height: inherit;
  vertical-align: inherit;

  &:focus {
    box-shadow: none;
  }
`
