import { useEffect } from "react"

import { Loading } from "../components/Common"
import { SearchList } from "../components/SearchList"
import { useDeletePerson } from "../graphql/delete-person"
import { useGetAllPeople } from "../graphql/get-all-people"
import { usePath } from "../hooks/use-path"
import { getLogger } from "../utils/logging"

const logger = getLogger("SearchContainer")

export const SearchContainer = () => {
  const {
    pathState: { focusPersonId },
    setPathState,
  } = usePath()

  const { error, loading, people } = useGetAllPeople()
  const { deletePerson } = useDeletePerson()

  useEffect(
    () => () => {
      setPathState({ focusPersonId: undefined })
    },
    [setPathState, focusPersonId]
  )

  if (error) return <p>Error :(</p>
  if (loading) return <Loading />
  if (!people) return <p>Error fetching from GraphQL</p>

  const handleDelete = async (id: string) => {
    try {
      await deletePerson(id)
    } catch (e) {
      logger.error(e)
    }
  }

  return (
    <SearchList
      people={people}
      focusPersonId={focusPersonId}
      onDelete={handleDelete}
    />
  )
}
