import { createContext, useContext, useState, ReactNode } from "react"

interface PathState {
  focusPersonId?: string
}

interface PathContextProps {
  pathState: PathState
  setPathState: React.Dispatch<React.SetStateAction<PathState>>
}

const PathContext = createContext({} as PathContextProps)
export const usePath = () => useContext(PathContext)

interface PathProviderProps {
  children: ReactNode
}

export const PathProvider = ({ children }: PathProviderProps) => {
  const value = useProvidePath()
  return <PathContext.Provider value={value}>{children}</PathContext.Provider>
}

const useProvidePath = () => {
  const [pathState, setPathState] = useState<PathState>({})
  return { pathState, setPathState }
}
