import cx from "classnames"
import { ReactNode } from "react"
import { Container, Stack } from "react-bootstrap"
import { createGlobalStyle } from "styled-components"

import { Topbar } from "../components/Topbar/Topbar"

type width = "fluid" | "narrow"
type height = "fluid" | "fill"

interface LayoutProps {
  children: ReactNode
  width?: width
  height?: height
}

export const Layout = ({
  children,
  width = "fluid",
  height = "fluid",
}: LayoutProps) => (
  <Stack className={cx({ "h-100": height === "fill" })}>
    {height === "fill" && <FillHeightStyle />}
    <Topbar />
    <Container
      as="main"
      className={cx("flex-grow-1", {
        "container-narrow": width === "narrow",
      })}
    >
      {children}
    </Container>
  </Stack>
)

const FillHeightStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
  }

  #root {
    height: 100%;
  }
`
