import { useMutation } from "@apollo/client"

import { getLogger } from "../utils/logging"
import { graphql } from "./generated/gql"
import { UpdatePersonInput } from "./generated/graphql"
import { GET_ALL_PEOPLE } from "./get-all-people"

const logger = getLogger("useEditPerson")

export const useEditPerson = () => {
  const [mutation, { data, loading, error }] = useMutation(EDIT_PERSON, {
    update(cache, { data }) {
      const updatedPerson = data?.updatePerson
      if (!updatedPerson) return
      const query = cache.readQuery({ query: GET_ALL_PEOPLE })
      // If cache is empty, don't try and populate it with just our object
      if (!query || !query?.people) return
      const people = query.people.map((p) =>
        p?.id === updatedPerson.id ? updatedPerson : p
      )
      cache.writeQuery({
        query: GET_ALL_PEOPLE,
        data: { people },
        overwrite: true,
      })
    },
  })

  const editPerson = async (person: UpdatePersonInput) =>
    await mutation({ variables: { person } })
  if (error) logger.error(error)

  return { editPerson, data, loading, error }
}

export const EDIT_PERSON = graphql(`
  mutation UpdatePerson($person: UpdatePersonInput!) {
    updatePerson(person: $person) {
      ...PersonFragment
      spouses {
        id
        type
      }
      siblings {
        id
        type
      }
      parents {
        id
        type
      }
      children {
        id
        type
      }
    }
  }
`)
