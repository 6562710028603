import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider as ApolloProviderBase,
  NormalizedCacheObject,
} from "@apollo/client"
import { ReactNode, useEffect } from "react"

import { useAuth, FetchFunction } from "./use-auth"

export const ApolloProvider = ({ children }: { children: ReactNode }) => {
  const client = useApolloClient()
  return <ApolloProviderBase client={client}>{children}</ApolloProviderBase>
}

let customFetch: FetchFunction = fetch

const cache = new InMemoryCache({
  typePolicies: {
    Relation: {
      keyFields: ["id", "type"],
    },
  },
})
const link = createHttpLink({
  fetch: (...args) => customFetch(...args),
  uri: process.env.REACT_APP_GRAPHQL_URL,
  ...(process.env.NODE_ENV !== "production" && {
    connectToDevTools: true,
  }),
})
const client = new ApolloClient<NormalizedCacheObject>({ link, cache })

const useApolloClient = () => {
  const { fetchWithAuth, isLoggedIn } = useAuth()

  customFetch = isLoggedIn ? fetchWithAuth : fetch

  useEffect(() => {
    if (!isLoggedIn) {
      client.resetStore()
    }
  }, [isLoggedIn])

  return client
}
