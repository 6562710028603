import { Card, Stack } from "react-bootstrap"
import { Title } from "react-head"

import { PageTitle, BackButton } from "../../components/Common"
import { AddPersonContainer } from "../../containers/Person/AddPersonContainer"
import { Layout } from "../../layouts/Layout"

export const AddPerson = () => (
  <Layout width="narrow">
    <Title>Ancestry Project :: Add Person</Title>
    <Stack direction="horizontal" className="py-3">
      <PageTitle>
        <BackButton />
        Add Person
      </PageTitle>
    </Stack>

    <Card className="p-4">
      <AddPersonContainer />
    </Card>
  </Layout>
)
