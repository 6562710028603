import { Container, Navbar, Nav, Form, InputGroup } from "react-bootstrap"
import { BsSearch, BsDiagram2 } from "react-icons/bs"
import { Link, useMatch } from "react-router-dom"
import styled from "styled-components"

import { NotificationDropdown } from "./NotificationDropdown"
import { ProfileDropdown } from "./ProfileDropdown"

const NavLink = (props: any) => {
  const match = useMatch(props.to + "/*")
  return <Nav.Link as={Link} active={match} {...props} />
}

// TODO: Fix active navlink matching (all people routes should be under search)
export const Topbar = () => (
  <Navbar bg="white" expand="lg" className="shadow-sm nav">
    <Container>
      <Navbar.Brand as={Link} to="/">
        Ancestry Project
      </Navbar.Brand>
      <Nav>
        <NavLink to="/graph">
          <BsDiagram2 className="me-2" />
          Graph
        </NavLink>
        <NavLink to="/search">
          <BsSearch className="me-2" />
          Search
        </NavLink>
      </Nav>
      <div className="ms-auto d-lg-none"></div>
      <Form className="ms-auto d-flex d-none d-lg-flex">
        <SearchInputGroup className="ms-auto" size="sm">
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            aria-describedby="search-icon"
          />
          <InputGroup.Text id="search-icon">
            <BsSearch />
          </InputGroup.Text>
        </SearchInputGroup>
      </Form>
      <NotificationDropdown />
      <ProfileDropdown />
    </Container>
  </Navbar>
)

const SearchInputGroup = styled(InputGroup)`
  & > .form-control {
    font-size: 0.875rem;
  }
`
