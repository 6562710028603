import { Alert, Card } from "react-bootstrap"
import { Title } from "react-head"
import { useParams } from "react-router-dom"

import { ViewPersonContainer } from "../../containers/Person/ViewPersonContainer"
import { Layout } from "../../layouts/Layout"

export const ViewPerson = () => {
  const { id } = useParams()

  return (
    <Layout width="narrow">
      <Title>Ancestry Project</Title>
      <Card className="mt-4 p-4">
        {id ? (
          <ViewPersonContainer id={id} />
        ) : (
          <Alert variant="warning">No person found</Alert>
        )}
      </Card>
    </Layout>
  )
}
