import { useQuery } from "@apollo/client"

import { notEmpty } from "../utils/types"
import { graphql } from "./generated/gql"

export const useGetAllPeople = () => {
  const { error, loading, data } = useQuery(GET_ALL_PEOPLE)
  const people = data && data.people.filter(notEmpty).sort(sortByName)
  return { people, error, loading }
}

type Name = {
  firstName?: string | null
  lastName?: string | null
}

const sortByName = (a: Name, b: Name) =>
  sortAlpha(a.firstName, b.firstName) || sortAlpha(a.lastName, b.lastName)

const sortAlpha = (a?: string | null, b?: string | null) =>
  !a ? -1 : !b ? 1 : a.localeCompare(b)

export const GET_ALL_PEOPLE = graphql(`
  query GetAllPeople {
    people {
      ...PersonFragment
    }
  }
`)
