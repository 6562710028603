import { Title } from "react-head"

import { Loading } from "../../components/Common"
import { PersonCard } from "../../components/Person/PersonCard"
import { useGetPerson } from "../../graphql/get-person"
import { getFullName } from "../../utils/person"

interface ViewPersonContainerProps {
  id: string
}

export const ViewPersonContainer = ({ id }: ViewPersonContainerProps) => {
  const { person, error, loading } = useGetPerson(id)

  if (id === null) return <p>Bad request</p>
  if (error) return <p>Error loading person {id}</p>
  if (loading) return <Loading />
  if (!person) return <p>Person not found {id}</p>

  return (
    <>
      <Title>Ancestry Project :: {getFullName(person)}</Title>
      <PersonCard person={person} />
    </>
  )
}
