import { Button, ButtonProps, Spinner } from "react-bootstrap"

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
}

export const LoadingButton = ({
  children,
  loading,
  ...props
}: LoadingButtonProps) => (
  <Button {...props} disabled={loading}>
    {loading ? (
      <>
        <Spinner
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">Loading...</span>
      </>
    ) : (
      children
    )}
  </Button>
)
