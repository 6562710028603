import ClientOAuth2, { Token, Request } from "client-oauth2"
import jwtDecode from "jwt-decode"
import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react"

import { getLogger } from "../utils/logging"

const logger = getLogger("useAuth")

const authUrl = process.env.REACT_APP_AUTH_URL || ""
const clientId = process.env.REACT_APP_CLIENT_ID || ""
const clientSecret = process.env.REACT_APP_CLIENT_SECRET || ""
const appUrl =
  process.env.NODE_ENV === "production"
    ? "https://www.ancestry-project.com"
    : "http://localhost:3000"
const STORAGE_KEY = "authToken"
const SCOPES = [
  "email",
  "profile",
  "openid",
  "AncestryProject-AncestryProjectGraph/*",
]
let refreshTokenPromise: Promise<ExtendedToken> | null = null

type ExtendedToken = Token & {
  expires: Date
  sign(init: RequestInit): RequestInit
}

export type FetchFunction = (
  url: RequestInfo | URL,
  options?: RequestInit
) => Promise<Response>

export type User = {
  email: string
  emailVerified: boolean
  id: string
  personId: string
  phone: string
  phoneVerified: boolean
}

export type UseAuth = {
  error: string | null
  fetchWithAuth: FetchFunction
  isLoggedIn: boolean
  loading: boolean
  signIn: () => void
  signInCallback: () => void
  signOut: () => void
  user: User | null
}

const AuthContext = createContext<UseAuth | null>(null)
export const useAuth = (): UseAuth => {
  const authContext = useContext(AuthContext)
  if (!authContext) {
    throw new Error(
      "No auth context, did you forget to include AuthProvider in the component tree?"
    )
  }
  return authContext
}

interface AuthProviderProps {
  children?: ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const value = useProvideAuth()
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const getClient = () =>
  new ClientOAuth2(
    {
      accessTokenUri: `${authUrl}/oauth2/token`,
      authorizationUri: `${authUrl}/oauth2/authorize`,
      clientId,
      clientSecret,
      redirectUri: `${appUrl}/callback`,
      scopes: SCOPES,
    },
    request
  )

const getStoredToken = (client: ClientOAuth2) => {
  if (typeof window === "undefined") {
    return null
  }

  try {
    const item = window.localStorage.getItem(STORAGE_KEY)
    if (!item) {
      return null
    }
    const token = client.createToken(JSON.parse(item))
    try {
      const expiresAt = new Date(token.data.expires)
      if (!isNaN(expiresAt as unknown as number)) {
        token.expiresIn(expiresAt)
      }
    } catch (error) {
      logger.info("Unable to parse auth token expiration", error)
    }
    return token as ExtendedToken
  } catch (error) {
    logger.info("Failed to read auth token from localStorage", error)
    return null
  }
}

const setStoredToken = (token: ExtendedToken | null) => {
  try {
    if (!token) {
      window.localStorage.removeItem(STORAGE_KEY)
    } else {
      const storedToken = {
        ...token.data,
        expires: token.expires.toISOString(),
      }
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(storedToken))
    }
  } catch (error) {
    logger.error("Failed to save auth token to localStorage", error)
  }
}

const useProvideAuth = (): UseAuth => {
  const [client] = useState<ClientOAuth2>(getClient)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [token, setToken] = useState<ExtendedToken | null>(() =>
    getStoredToken(client)
  )

  useEffect(() => {
    setStoredToken(token)
  }, [token])

  const refreshTokenOnce = async (
    token: ExtendedToken
  ): Promise<ExtendedToken> => {
    try {
      const newToken = (await token.refresh()) as ExtendedToken
      setToken(newToken)
      return newToken
    } catch (error) {
      logger.warn("Failed to refresh token", { error })
      await signOut()
      throw error
    } finally {
      refreshTokenPromise = null
    }
  }

  const refreshToken = async (token: ExtendedToken): Promise<ExtendedToken> => {
    if (!refreshTokenPromise) {
      refreshTokenPromise = refreshTokenOnce(token)
    }
    return refreshTokenPromise
  }

  const fetchWithAuth = async (
    url: RequestInfo | URL,
    options?: RequestInit
  ) => {
    if (!token) {
      throw new Error("User is not logged in")
    }

    let validToken = token
    if (validToken.expires < new Date()) {
      logger.info("Token has expired, refreshing")
      validToken = await refreshToken(validToken)
    }

    const response = await fetch(url, validToken.sign(options || {}))
    if (response.status === 401) {
      validToken = await refreshToken(validToken)
      return fetch(url, validToken.sign(options || {}))
    }
    return response
  }

  const signIn = () => {
    const uri = client.code.getUri()
    window.location.href = uri
  }

  const signInCallback = async () => {
    setLoading(true)
    setError(null)
    try {
      const token = await client.code.getToken(window.location)
      setToken(token as ExtendedToken)
    } catch (error) {
      logger.error("Sign in error", error)
      setError(String(error))
    } finally {
      setLoading(false)
    }
  }

  const signOut = async () => {
    if (!token) {
      return
    }
    try {
      await fetch(`${authUrl}/oauth2/revoke`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Basic " + btoa(`${clientId}:${clientSecret}`),
        },
        body: new URLSearchParams({ token: token.data.refresh_token }),
      })
    } catch (error) {
      logger.info("Failed to revoke token", error)
    }
    setToken(null)

    window.location.href =
      `${authUrl}/logout?` +
      new URLSearchParams({
        client_id: clientId,
        redirect_uri: `${appUrl}/callback`,
        response_type: "code",
        scope: SCOPES.join(" "),
      })
  }

  const user = token && mapUser(jwtDecode(token.data.id_token))

  return {
    error,
    fetchWithAuth,
    isLoggedIn: !!token,
    loading,
    signIn,
    signInCallback,
    signOut,
    user,
  }
}

const mapUser = (decodedToken: any): User => ({
  id: decodedToken.sub,
  email: decodedToken.email,
  emailVerified: decodedToken.email_verified,
  personId: decodedToken["custom:personId"],
  phone: decodedToken.phone_number,
  phoneVerified: decodedToken.phone_number_verified,
})

const request: Request = async (method, url, body, headers) => {
  const response = await fetch(url, {
    body,
    headers: headers as HeadersInit,
    method,
  })
  const bodyText = await response.text()
  return { body: bodyText, status: response.status }
}
