import ReactFamilyTree from "react-family-tree"
import { Node, ExtNode, Gender, Relation } from "relatives-tree/lib/types"
import styled from "styled-components"

import { Person } from "../../graphql/generated/graphql"
import { useQueryStringState } from "../../hooks/use-querystring-state"
import { GraphNode } from "./GraphNode"

const WIDTH = 180
const HEIGHT = 80

type PersonNode = Node & {
  person?: Person
}
type ExtPersonNode = ExtNode & {
  person?: Person
}

interface GraphProps {
  people: Person[]
  rootId: string
}

export const Graph = ({ people, rootId }: GraphProps) => {
  const [, setRootId] = useQueryStringState("root-id")
  const nodes = people.map(
    (person: Person): PersonNode =>
      // TODO: Make person.id mandatory?
      // We should be properly mapping Gender and RelType to allow our code to add more options
      // but still map down to know relatives-tree types.
      ({
        id: person.id || "",
        gender: person.gender as Gender,
        parents: (person.parents || []) as Relation[],
        children: (person.children || []) as Relation[],
        siblings: (person.siblings || []) as Relation[],
        spouses: (person.spouses || []) as Relation[],
        person,
      })
  )
  return (
    <TreeContainer>
      <ReactFamilyTree
        nodes={nodes}
        rootId={rootId}
        width={WIDTH}
        height={HEIGHT}
        renderNode={(node: ExtPersonNode) =>
          node.person && (
            <GraphNode
              key={node.id}
              person={node.person}
              isRoot={node.id === rootId}
              onSubClick={(id) => id && setRootId(id)}
              width={WIDTH}
              height={HEIGHT}
              top={node.top}
              left={node.left}
              hasSubTree={node.hasSubTree}
            />
          )
        }
      />
    </TreeContainer>
  )
}

const TreeContainer = styled.div`
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  height: 100%;
`
