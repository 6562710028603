/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation AddPerson($person: AddPersonInput!) {\n    addPerson(person: $person) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n": types.AddPersonDocument,
    "\n  mutation DeletePerson($id: ID!) {\n    deletePerson(id: $id) {\n      id\n    }\n  }\n": types.DeletePersonDocument,
    "\n  mutation UpdatePerson($person: UpdatePersonInput!) {\n    updatePerson(person: $person) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n": types.UpdatePersonDocument,
    "\n  query GetAllPeople {\n    people {\n      ...PersonFragment\n    }\n  }\n": types.GetAllPeopleDocument,
    "\n  query GetGraph($rootId: ID!) {\n    graph(rootId: $rootId) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n": types.GetGraphDocument,
    "\n  query GetPerson($personId: ID!) {\n    person(id: $personId) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      siblings {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      parents {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      children {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n    }\n  }\n": types.GetPersonDocument,
    "\n  fragment PersonFragment on Person {\n    birthDate\n    deathDate\n    firstName\n    gender\n    id\n    lastName\n    media {\n      id\n      contentType\n      key\n      bucket\n      filename\n      size\n      type\n    }\n    middleName\n    title\n  }\n": types.PersonFragmentFragmentDoc,
    "\n  mutation AddMedia($media: AddMediaInput!) {\n    addMedia(media: $media) {\n      id\n      contentType\n      key\n      bucket\n      filename\n      size\n      type\n    }\n  }\n": types.AddMediaDocument,
    "\n  query GetUploadUrl($personId: ID!, $contentType: String!) {\n    uploadUrl(personId: $personId, contentType: $contentType)\n  }\n": types.GetUploadUrlDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddPerson($person: AddPersonInput!) {\n    addPerson(person: $person) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddPerson($person: AddPersonInput!) {\n    addPerson(person: $person) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeletePerson($id: ID!) {\n    deletePerson(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeletePerson($id: ID!) {\n    deletePerson(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdatePerson($person: UpdatePersonInput!) {\n    updatePerson(person: $person) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdatePerson($person: UpdatePersonInput!) {\n    updatePerson(person: $person) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAllPeople {\n    people {\n      ...PersonFragment\n    }\n  }\n"): (typeof documents)["\n  query GetAllPeople {\n    people {\n      ...PersonFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetGraph($rootId: ID!) {\n    graph(rootId: $rootId) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetGraph($rootId: ID!) {\n    graph(rootId: $rootId) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n      }\n      siblings {\n        id\n        type\n      }\n      parents {\n        id\n        type\n      }\n      children {\n        id\n        type\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPerson($personId: ID!) {\n    person(id: $personId) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      siblings {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      parents {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      children {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPerson($personId: ID!) {\n    person(id: $personId) {\n      ...PersonFragment\n      spouses {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      siblings {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      parents {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n      children {\n        id\n        type\n        person {\n          ...PersonFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PersonFragment on Person {\n    birthDate\n    deathDate\n    firstName\n    gender\n    id\n    lastName\n    media {\n      id\n      contentType\n      key\n      bucket\n      filename\n      size\n      type\n    }\n    middleName\n    title\n  }\n"): (typeof documents)["\n  fragment PersonFragment on Person {\n    birthDate\n    deathDate\n    firstName\n    gender\n    id\n    lastName\n    media {\n      id\n      contentType\n      key\n      bucket\n      filename\n      size\n      type\n    }\n    middleName\n    title\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddMedia($media: AddMediaInput!) {\n    addMedia(media: $media) {\n      id\n      contentType\n      key\n      bucket\n      filename\n      size\n      type\n    }\n  }\n"): (typeof documents)["\n  mutation AddMedia($media: AddMediaInput!) {\n    addMedia(media: $media) {\n      id\n      contentType\n      key\n      bucket\n      filename\n      size\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUploadUrl($personId: ID!, $contentType: String!) {\n    uploadUrl(personId: $personId, contentType: $contentType)\n  }\n"): (typeof documents)["\n  query GetUploadUrl($personId: ID!, $contentType: String!) {\n    uploadUrl(personId: $personId, contentType: $contentType)\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;