import differenceInYears from "date-fns/differenceInYears"
import parseISO from "date-fns/parseISO"

type Person = {
  gender?: string | null
}

type Relation<T extends Person> = {
  id: string
  type: string
  person?: T
}

const getParent = <T extends Person>(
  parents: Relation<T>[],
  gender: string
): T | null => {
  const parent = parents?.find(
    (r) => r.type === "blood" && r.person?.gender === gender
  )
  return parent?.person || null
}

export const getMother = <T extends Person>(parents: Relation<T>[]): T | null =>
  getParent(parents, "female")

export const getFather = <T extends Person>(parents: Relation<T>[]): T | null =>
  getParent(parents, "male")

export type GetFullNameProps = {
  firstName?: string | null
  lastName?: string | null
}

export const getFullName = (p: GetFullNameProps): string =>
  `${p.firstName ?? ""} ${p.lastName ?? ""}`.trim()

export type GetAgeProps = {
  birthDate?: string | null
  deathDate?: string | null
}

const getAge = (birthDate?: Date, deathDate?: Date): number | null => {
  if (!birthDate) {
    return null
  }
  return differenceInYears(deathDate || new Date(), birthDate)
}

export const getAgeRange = ({ birthDate, deathDate }: GetAgeProps) => {
  const birth = birthDate ? parseISO(birthDate) : undefined
  const death = deathDate ? parseISO(deathDate) : undefined
  const birthYear = birth?.getFullYear()
  const deathYear = death?.getFullYear()
  const age = getAge(birth, death)
  const ageString = age ? ` (${age}y)` : ""

  if (!birthYear) {
    return ""
  } else if (!deathYear) {
    return `${birthYear} - Living${ageString}`
  } else {
    return `${birthYear} - ${deathYear}${ageString}`
  }
}
