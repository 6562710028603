import { math } from "polished"
import { useRef, useEffect } from "react"
import { Table } from "react-bootstrap"
import { BsTrashFill, BsFillPencilFill } from "react-icons/bs"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { PersonFragmentFragment as Person } from "../graphql/generated/graphql"
import styleVars from "../styles/_exports.module.scss"
import { getAgeRange, getFullName } from "../utils/person"
import { Avatar } from "./Common"
import { DeleteConfirm } from "./DeleteConfirm"

interface SearchListProps {
  people: Person[]
  focusPersonId?: string
  onDelete(id: string): Promise<void>
}

export const SearchList = ({
  people,
  focusPersonId,
  onDelete,
}: SearchListProps) => {
  const focusedRef = useRef<HTMLTableRowElement>(null)

  useEffect(() => {
    if (focusedRef.current) {
      focusedRef.current.scrollIntoView()
    }
  }, [focusedRef])

  if (people.length === 0) {
    return <p>No Data</p>
  }

  return (
    <StyledTable hover={true}>
      <tbody>
        {people.map((item: Person) => {
          const rowProps =
            item.id === focusPersonId
              ? { ref: focusedRef, className: "table-primary" }
              : {}
          return (
            <tr key={item.id} {...rowProps}>
              <td>
                <Link to={`/person/${item.id}`}>
                  <Avatar />
                </Link>
              </td>
              <td style={{ width: "100%" }}>
                <StyledLink to={`/person/${item.id}`}>
                  <Name>{getFullName(item)}</Name>
                  <Age>{getAgeRange(item)}</Age>
                </StyledLink>
              </td>
              <td>
                <Actions>
                  <Link to={`/person/${item.id}/edit`}>
                    <BsFillPencilFill />
                    <span className="visually-hidden">edit</span>
                  </Link>
                  <DeleteConfirm
                    title={`Are you sure you want to delete ${getFullName(
                      item
                    )}?`}
                    onConfirm={onDelete.bind(this, item.id || "")}
                  >
                    <BsTrashFill />
                    <span className="visually-hidden">delete</span>
                  </DeleteConfirm>
                </Actions>
              </td>
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }
`

const Name = styled.span`
  font-weight: 600;
  color: ${styleVars["body-color"]};
  margin-right: ${math(`${styleVars["spacer"]} * 0.5`)};
`

const Age = styled.span`
  color: ${styleVars["gray-600"]};
`

const Actions = styled.div`
  display: flex;
  svg {
    color: ${styleVars["gray-500"]};
    width: 1rem;
    height: 1rem;

    &:first-child {
      margin-right: 1rem;
    }
  }
  .table-primary & svg {
    color: ${styleVars["gray-600"]};
  }
  tr:hover & svg {
    color: ${styleVars["gray-600"]};
  }
`

const StyledTable = styled(Table).attrs({ className: "align-middle" })`
  > :not(caption) > * > * {
    padding: 1rem 0.5rem;
  }
`
