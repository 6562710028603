import { Card } from "react-bootstrap"
import { BsPersonCircle } from "react-icons/bs"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { Person } from "../../graphql/generated/graphql"
import styleVars from "../../styles/_exports.module.scss"
import { getAgeRange, getFullName } from "../../utils/person"

interface PersonBadgeProps {
  person: Person
  isRoot: boolean
}

export const PersonBadge = ({ person, isRoot }: PersonBadgeProps) => (
  <StyledLink to={`/person/${person.id}`}>
    <StyledCard>
      <AvatarBlock>
        <BsPersonCircle color="white" size="2em" />
      </AvatarBlock>
      <DetailsBlock>
        <Name>{getFullName(person)}</Name>
        <Age>{getAgeRange(person)}</Age>
      </DetailsBlock>
    </StyledCard>
  </StyledLink>
)

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${styleVars["body-color"]};
`

const Name = styled.div``
const Age = styled.div`
  font-size: 0.84em;
`

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

const DetailsBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex-grow: 1;
  padding: 0.25rem 0.5rem;
  line-height: 1em;
  font-size: 0.86em;
`

const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
  background-color: ${styleVars["gray-500"]};
  padding: 0.24rem;
  width: 60px;
  height: 100%;

  svg {
    display: block;
    margin: auto;
  }
`
