import { Typeahead } from "react-bootstrap-typeahead"
import { Option } from "react-bootstrap-typeahead/types/types"
import { useController, UseControllerProps } from "react-hook-form"

import { Person } from "../../graphql/generated/graphql"
import { useGetAllPeople } from "../../graphql/get-all-people"
import { getFullName } from "../../utils/person"
import { notEmpty } from "../../utils/types"
import { FormInputs } from "./types"

type PersonSelectProps = UseControllerProps<
  FormInputs,
  "mother" | "father" | "spouse"
>

export const PersonSelect = (props: PersonSelectProps) => {
  const { error: fetchError, loading, people } = useGetAllPeople()
  const {
    field: { name, onBlur, onChange, ref, value },
  } = useController(props)

  if (loading) return <p>Loading...</p>
  if (fetchError) return <p>{fetchError.message}</p>

  const handleChange = (selected: any[]) =>
    selected.length > 0 && onChange(selected[0].id)

  return (
    <Typeahead
      clearButton
      id={`${name}-select`}
      inputProps={{ id: name }}
      labelKey={(option: Option) => getFullName(option as Person)}
      onBlur={onBlur}
      onChange={handleChange}
      options={people || []}
      ref={ref}
      defaultSelected={people
        ?.filter((person) => person.id === value)
        .slice(0, 1)}
    />
  )
}

type PeopleSelectProps = UseControllerProps<FormInputs, "siblings" | "children">

export const PeopleSelect = (props: PeopleSelectProps) => {
  const { error: fetchError, loading, people } = useGetAllPeople()
  const {
    field: { name, onBlur, onChange, ref, value },
  } = useController(props)

  if (loading) return <p>Loading...</p>
  if (fetchError) return <p>{fetchError.message}</p>

  const handleChange = (selected: any[]) => onChange(selected.map((x) => x.id))
  const selected =
    people &&
    value?.map((id: string) => people.find((x) => x.id === id)).filter(notEmpty)

  return (
    <Typeahead
      id={name}
      labelKey={(option: Option) => getFullName(option as Person)}
      multiple
      onBlur={onBlur}
      onChange={handleChange}
      options={people || []}
      ref={ref}
      defaultSelected={selected}
    />
  )
}
