import React from "react"
import styled from "styled-components"

import { Maybe, Person } from "../../graphql/generated/graphql"
import { PersonBadge } from "./PersonBadge"

interface Props {
  person: Person
  isRoot: boolean
  onSubClick: (id?: string) => void
  width: number
  height: number
  top: number
  left: number
  hasSubTree: boolean
}

export const GraphNode = React.memo<Props>(
  ({ person, isRoot, onSubClick, width, height, top, left, hasSubTree }) => (
    <StyledGraphNode
      width={width}
      height={height}
      left={left}
      top={top}
      title={person.id}
    >
      <PersonBadge person={person} isRoot={isRoot} />
      {hasSubTree && (
        <StyledSub
          gender={person.gender}
          onClick={() => onSubClick(person.id)}
        />
      )}
    </StyledGraphNode>
  )
)

interface StyledGraphNodeProps {
  width: number
  height: number
  left: number
  top: number
}

const StyledGraphNode = styled.div<StyledGraphNodeProps>`
  position: absolute;
  padding: 10px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  transform: translate(
    ${(props) => props.left * (props.width / 2)}px,
    ${(props) => props.top * (props.height / 2)}px
  );
`

interface StyledSubProps {
  gender?: Maybe<string>
}

const StyledSub = styled.div<StyledSubProps>`
  position: absolute;
  top: 6px;
  right: 14px;
  width: 14px;
  height: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px 0;
  background: ${(props) => (props.gender === "male" ? "#fdaed8" : "#a4ecff")};
  cursor: pointer;
`
