import { useState, useRef, ReactNode, useEffect } from "react"
import { useAsyncCallback } from "react-async-hook"
import { Overlay, Popover } from "react-bootstrap"

import { getLogger } from "../utils/logging"
import { LoadingButton, IconButton } from "./Common"

const logger = getLogger("DeleteConfirm")

interface DeleteConfirmProps {
  onConfirm(): Promise<void>
  title: string
  children: ReactNode
}

export const DeleteConfirm = ({
  onConfirm,
  title,
  children,
}: DeleteConfirmProps) => {
  const { loading, execute, error } = useAsyncCallback(onConfirm)
  const [show, setShow] = useState(false)
  const target = useRef(null)

  useEffect(() => {
    if (error && show) {
      logger.error(error)
      setShow(false)
    }
  }, [error, show])

  return (
    <>
      <IconButton ref={target} onClick={() => setShow(!show)}>
        {children}
      </IconButton>
      <Overlay
        target={target.current}
        show={show}
        placement="left"
        rootClose={true}
        onHide={() => setShow(false)}
      >
        {(props) => (
          <Popover id="popover-basic" {...props}>
            <Popover.Body>
              <div>{title}</div>
              <div className="text-end mt-2">
                <LoadingButton
                  variant="outline-secondary"
                  size="sm"
                  className="me-2"
                  onClick={() => setShow(false)}
                >
                  No
                </LoadingButton>
                <LoadingButton
                  variant="primary"
                  size="sm"
                  onClick={execute}
                  loading={loading}
                >
                  Yes
                </LoadingButton>
              </div>
            </Popover.Body>
          </Popover>
        )}
      </Overlay>
    </>
  )
}
