import { useLazyQuery, useMutation } from "@apollo/client"
import { useState } from "react"

import { graphql } from "./generated/gql"

//type MediaType = "image" | "video" | "audio"

export const useUploadMedia = () => {
  const [error, setError] = useState<Error>()
  const [getUploadUrl, { error: queryError, loading: queryIsLoading }] =
    useLazyQuery(GET_UPLOAD_URL, {
      fetchPolicy: "network-only",
    })
  const [mutation, { data, loading: mutationIsLoading, error: mutationError }] =
    useMutation(ADD_MEDIA)

  const uploadMedia = async ({
    file,
    personId,
    type,
  }: {
    file: File
    personId: string
    type: string
  }) => {
    try {
      // Get the signed URL from the server
      const { data } = await getUploadUrl({
        variables: { personId, contentType: file.type },
      })
      const uploadUrl = data?.uploadUrl
      if (!uploadUrl) {
        throw new Error("Invalid upload URL")
      }
      const key = uploadUrl.split("?")[0].split("/").pop()
      if (!key) {
        throw new Error("Invalid upload key")
      }
      // Upload the file to the signed URL
      await fetch(uploadUrl, {
        body: file,
        headers: {
          "Content-Type": file.type,
        },
        method: "PUT",
      })
      // Send the file metadata to the server
      await mutation({
        variables: {
          media: {
            filename: file.name,
            key,
            personId,
            type,
          },
        },
      })
    } catch (error) {
      setError(error as unknown as Error)
    }
  }

  return {
    uploadMedia,
    data,
    loading: mutationIsLoading || queryIsLoading,
    error: error || mutationError || queryError,
  }
}

export const ADD_MEDIA = graphql(`
  mutation AddMedia($media: AddMediaInput!) {
    addMedia(media: $media) {
      id
      contentType
      key
      bucket
      filename
      size
      type
    }
  }
`)

export const GET_UPLOAD_URL = graphql(`
  query GetUploadUrl($personId: ID!, $contentType: String!) {
    uploadUrl(personId: $personId, contentType: $contentType)
  }
`)
