import { Title } from "react-head"

import { GraphContainer } from "../containers/GraphContainer"
import { Layout } from "../layouts/Layout"

export const Graph = () => (
  <Layout height="fill">
    <Title>Ancestry Project :: Graph</Title>
    <GraphContainer />
  </Layout>
)
