import { Alert, Card, Stack } from "react-bootstrap"
import { Title } from "react-head"
import { useParams } from "react-router-dom"

import { PageTitle, BackButton } from "../../components/Common"
import { EditPersonContainer } from "../../containers/Person/EditPersonContainer"
import { Layout } from "../../layouts/Layout"

export const EditPerson = () => {
  const { id } = useParams()

  return (
    <Layout width="narrow">
      <Title>Ancestry Project :: Edit Person</Title>
      <Stack direction="horizontal" className="py-3">
        <PageTitle>
          <BackButton />
          Edit Person
        </PageTitle>
      </Stack>

      <Card className="p-4">
        {id ? (
          <EditPersonContainer id={id} />
        ) : (
          <Alert variant="warning">No person found</Alert>
        )}
      </Card>
    </Layout>
  )
}
