import { BsArrowLeft } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import styleVars from "../../styles/_exports.module.scss"
import { IconButton } from "./IconButton"

export const BackButton = () => {
  const navigate = useNavigate()

  return (
    <StyledButton onClick={() => navigate(-1)}>
      <BsArrowLeft />
      <span className="visually-hidden">Back</span>
    </StyledButton>
  )
}

const StyledButton = styled(IconButton)`
  margin-right: 0.5rem;
  color: ${styleVars["gray-500"]};
`
