import { useEffect } from "react"

import { useAuth } from "../hooks/use-auth"

export const Logout = () => {
  const { signOut } = useAuth()

  useEffect(() => {
    signOut()
  }, [signOut])

  return null
}
