import {
  Button,
  Row,
  Col,
  Stack,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import {
  BsFillSquareFill,
  BsPersonFill,
  BsGenderMale,
  BsGenderFemale,
  BsGenderAmbiguous,
} from "react-icons/bs"
import { BsFillPencilFill } from "react-icons/bs"
import { Link } from "react-router-dom"

import { PersonFragmentFragment as Person } from "../../graphql/generated/graphql"
import styleVars from "../../styles/_exports.module.scss"
import { getAgeRange } from "../../utils/person"
import { IconStack } from "../Common"

interface GenderIconProps {
  gender?: string | null
}

const GenderIcon = ({ gender }: GenderIconProps) => {
  if (!gender) return null
  const genderLower = gender.toLowerCase()

  const getGenderIcon = (gender: string) => {
    switch (gender) {
      case "male":
        return BsGenderMale
      case "female":
        return BsGenderFemale
      case "non-binary":
      case "transgender":
      case "intersex":
        return BsGenderAmbiguous
      default:
        return null
    }
  }
  const Icon = getGenderIcon(genderLower)

  if (Icon === null) return null
  return (
    <OverlayTrigger placement="auto" overlay={<Tooltip>{genderLower}</Tooltip>}>
      <span>
        <Icon size="0.8em" title={genderLower} />
      </span>
    </OverlayTrigger>
  )
}

interface PersonCardProps {
  person: Person
}

export const PersonCard = ({ person }: PersonCardProps) => {
  const getExtendedName = (person: Person) => (
    <>
      {person.title && person.title + ". "}
      {person.firstName && person.firstName + " "}
      {person.middleName && person.middleName + " "}
      {person.lastName && person.lastName + " "}
    </>
  )

  return (
    <Row>
      <Col>
        <Stack direction="horizontal" gap={3} className="align-items-start">
          <Stack
            direction="horizontal"
            gap={3}
            className="me-auto align-items-start"
          >
            <IconStack>
              <BsFillSquareFill color={styleVars["gray-500"]} size="4em" />
              <BsPersonFill color="white" size="3em" />
            </IconStack>
            <div>
              <h4>
                {getExtendedName(person)} <GenderIcon gender={person.gender} />
              </h4>
              <p>{getAgeRange(person)}</p>
            </div>
          </Stack>
          <Button
            as={Link as any}
            variant="primary"
            to={`/person/${person.id}/edit`}
          >
            <BsFillPencilFill className="me-2" />
            Edit Person
          </Button>
        </Stack>
      </Col>
    </Row>
  )
}
