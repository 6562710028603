import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Loading } from "../../components/Common"
import { PersonForm } from "../../components/Person/PersonForm"
import { FormInputs } from "../../components/Person/types"
import { useEditPerson } from "../../graphql/edit-person"
import { UpdatePersonInput } from "../../graphql/generated/graphql"
import { GetPersonQuery } from "../../graphql/generated/graphql"
import { useGetPerson } from "../../graphql/get-person"
import { useUploadMedia } from "../../graphql/upload-media"
import { usePath } from "../../hooks/use-path"
import { getLogger } from "../../utils/logging"
import { getFather, getMother } from "../../utils/person"
import { notEmpty } from "../../utils/types"

type Person = GetPersonQuery["person"]

const logger = getLogger("EditPersonContainer")

interface EditPersonContainerProps {
  id: string
}

const toValues = (person?: Person): FormInputs | undefined => {
  if (!person) return
  const {
    spouses,
    children,
    parents,
    birthDate,
    deathDate,
    siblings,
    ...props
  } = person
  return {
    ...props,
    birthDate,
    deathDate,
    mother: parents && getMother(parents)?.id,
    father: parents && getFather(parents)?.id,
    spouse: spouses?.[0]?.id,
    children: children?.map((x) => x.id),
    siblings: siblings?.map((x) => x.id),
  }
}

export const EditPersonContainer = ({ id }: EditPersonContainerProps) => {
  const navigate = useNavigate()
  const { setPathState } = usePath()
  const { person, error, loading } = useGetPerson(id)
  const {
    editPerson,
    loading: editingPerson,
    error: editError,
    data,
  } = useEditPerson()
  const {
    uploadMedia,
    loading: uploadingMedia,
    error: uploadError,
  } = useUploadMedia()

  const submitting = editingPerson || uploadingMedia
  const submitError = editError || uploadError

  useEffect(() => {
    if (data) {
      const updatedPersonId = data?.updatePerson?.id
      setPathState({ focusPersonId: updatedPersonId ?? undefined })
    }
  }, [data, setPathState])

  if (error) return <p>Error loading person {id}</p>
  if (loading) return <Loading />
  if (!person) return <p>Person not found {id}</p>

  if (submitError) return <p>Submission error! {submitError.message}</p>

  const handleSubmit = async ({
    avatar,
    birthDate,
    children,
    deathDate,
    father,
    firstName,
    gender,
    id,
    lastName,
    middleName,
    mother,
    siblings,
    spouse,
    title,
  }: FormInputs) => {
    if (!id) {
      throw new Error("Invalid operation, no id set")
    }

    const personInput: UpdatePersonInput = {
      id,
      title,
      firstName,
      middleName,
      lastName,
      gender,
      birthDate: birthDate ? birthDate : undefined,
      deathDate: deathDate ? deathDate : undefined,
      spouses: spouse ? [{ id: spouse, type: "blood" }] : [],
      siblings: siblings?.map((id: string) => ({ id, type: "blood" })) || [],
      parents: [
        mother ? { id: mother, type: "blood" } : undefined,
        father ? { id: father, type: "blood" } : undefined,
      ].filter(notEmpty),
      children: children?.map((id: string) => ({ id, type: "blood" })) || [],
    }
    try {
      await editPerson(personInput)
      const [file] = avatar || []
      if (file) {
        await uploadMedia({ file, personId: id, type: "image" })
      }
      navigate(`/search`)
    } catch (e) {
      logger.error(e)
    }
  }

  const defaultValues = toValues(person)
  return (
    <PersonForm
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      loading={submitting}
    />
  )
}
